export const initMobileNav = () => {
  const mobileNavIcon = document.getElementById("mobile-nav-icon");
  const topNavEl = document.getElementById("top-nav");
  const topNavLinks = topNavEl.querySelectorAll(".RS-link, .RS-button--nav");
  const topNavAnimDuration = 225;

  const toggleMobileNav = () => {
    if (mobileNavIcon.classList.contains("RS-top-bar__mobile-nav-icon--active") === false) {
      mobileNavIcon.classList.add("RS-top-bar__mobile-nav-icon--active");
      topNavEl.classList.add("RS-top-bar__nav--displayed");
      process.nextTick(() => topNavEl.classList.add("RS-top-bar__nav--opened"));
      setTimeout(() => {
        topNavLinks.forEach((topNavLink) => {
          topNavLink.classList.add("RS-link--visible")
        })
      }, topNavAnimDuration);

    } else {
      mobileNavIcon.classList.remove("RS-top-bar__mobile-nav-icon--active");
      topNavEl.classList.remove("RS-top-bar__nav--opened");
      setTimeout(() => {
        topNavLinks.forEach((topNavLink) => {
          topNavLink.classList.remove("RS-link--visible")
        })
      }, topNavAnimDuration);
    }
  };
  mobileNavIcon.addEventListener("click", toggleMobileNav);
  mobileNavIcon.addEventListener("keydown", (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) toggleMobileNav();
  });



  topNavLinks.forEach((topNavLink) => {
    topNavLink.addEventListener("click", toggleMobileNav);
    topNavLink.addEventListener("keydown", (e) => {
      const code = e.keyCode || e.which;
      if (code === 13) toggleMobileNav();
    });
  });

};
